import dayjs from "dayjs";

//const url = `https://photos-6gop0s5f730f5f58.ap-shanghai.kits.tcloudbasegateway.com`;
const apiUrl = `api/cms/5prw6wmjx4zxpnk3/v1/open-api/projects/photos/collections`; // /races/contents?limit=10&offset=0

export const getRacesByApiPage = async (page = 1, pageSize = 100) => {
  const res = await fetch(
    `${apiUrl}/races/contents?limit=${pageSize}&offset=${(page - 1) * pageSize}`
  ).then((res) => res.json());

  return res?.result?.data
    ?.map((item) => {
      item.raceDate = dayjs(item.date).format("YYYY-MM-DD");
      return item;
    })
    .sort((a, b) => b.orderNum - a.orderNum);
};
