import React, { useState, useEffect } from "react";
import { getRacesByApiPage } from "./api/api";
import RaceItem from "./components/race-item";
import favicon from "./images/icon.png";
import "./styles/index.scss";
import { wxShareInit } from "./wxUtil";

const pageSize = 100;
function App() {
  const [allRaces, setAllRaces] = useState([]);
  const [page, setPage] = useState(1);
  const [displayRaces, setDisplyRaces] = useState(allRaces);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    const loadRaces = async () => {
      const res = await getRacesByApiPage(1, pageSize);
      if (res.length >= pageSize) {
        setIsShowMore(true);
      }
      setAllRaces(res);
      setDisplyRaces(res);
    };
    loadRaces();
    loadWeixin();
  }, []);

  const loadWeixin = () => {
    try {
      if (typeof window.entryUrl === "undefined" || window.entryUrl === "") {
        window.entryUrl = encodeURIComponent(
          window.location.href.split("#")[0]
        );
      }
      const url = /Android/i.test(navigator.userAgent)
        ? encodeURIComponent(window.location.href)
        : window.entryUrl;
      wxShareInit(url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async (e) => {
    const kw = e.target.title.value;
    if (!kw) {
      setDisplyRaces(allRaces);
    }
    const filterRaces = allRaces.filter((item) => {
      return item.title.indexOf(kw) >= 0 || item.location.indexOf(kw) >= 0;
    });
    debugger;
    setDisplyRaces(filterRaces);
    e.preventDefault();
  };

  const showMore = async () => {
    const _races = await getRacesByApiPage(page + 1, pageSize);
    setPage(page + 1);
    console.log(_races);
    if (_races?.length > 0) {
      setAllRaces([...allRaces, ..._races]);
      setDisplyRaces([...allRaces, ..._races]);
      if (_races.length < pageSize) {
        setIsShowMore(false);
      }
    } else {
      setIsShowMore(false);
    }
  };

  return (
    <div className="container">
      <div style={{ margin: "0 auto", display: "none" }}>
        <img alt="派森视觉" src={favicon} />
      </div>
      <main className="main">
        <header className="header"></header>
        <section className="body">
          <form onSubmit={handleSearch} className="search flex row">
            <input
              className="flex-auto"
              type="text"
              name="title"
              placeholder="请输入赛事名称"
            />
            <input className="flex-none" type="submit" value="搜 索" />
          </form>
          <section className="list">
            {displayRaces?.map((item, index) => {
              return <RaceItem item={item} key={index}></RaceItem>;
            })}
            {isShowMore && (
              <div onClick={showMore} className="btn">
                点击查看更多
              </div>
            )}
          </section>
          <section className="beian">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              title="备案信息"
              rel="noreferrer"
            >
              沪ICP备2024084720号
            </a>
          </section>
        </section>
      </main>
    </div>
  );
}

export default App;
