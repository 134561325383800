import dateIcon from "./../images/date.png";
import locationIcon from "./../images/location.png";

export default function RaceItem({ item }) {
  return (
    <a href={`${item.albumUrl}`} key={item._id}>
      <div className="race-item flex row align-center">
        <div className="item-img flex align-center">
          <img
            src={item.coverUrl || ""}
            height={64}
            width={80}
            layout="responsive"
            alt={item.title}
          />
        </div>
        <div className="item-text flex-auto">
          <h5 className="max-line2">{item.title}</h5>
          <summary className="flex align-center max-line2">
            <img src={dateIcon} width={16} height={16} alt="date" />
            <span className="date">{item.raceDate}</span>
            <img src={locationIcon} width={16} height={16} alt="location" />
            <span className="location">{item.location}</span>
          </summary>
        </div>
        <div className="flex-none more"></div>
      </div>
    </a>
  );
}
